@import "../../../scss/config.scss";

.title-div{
    font-family: $theme-font-family;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 26px;
    color: #33485D;
    margin-bottom: 15px;
}