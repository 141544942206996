@import "../../scss/config.scss";

.close-icon{
    display: none;
}
.sidebar-div{
    overflow-x: hidden;
    transition: 0.5s;
    width: 40%;    
    background: $white;
    box-shadow: 5px 0px 8px rgba(0, 0, 0, 0.19);
    padding: 20px;

    .menu-style{
        border: unset;
    }

    .menu-heading{
        padding: 15px 20px;
        font-family: $theme-font-family;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        text-transform: uppercase;
        pointer-events: none;
        cursor: default;
    }
    .sub-menu-style{
        .ant-menu-sub{
            background: $white !important;
        }
        .ant-menu-submenu-title{
            color: black !important;
        }
        .ant-menu-submenu-arrow{
            color: black !important;
        }
        .ant-menu-submenu-title:hover {
            font-weight: bold;
            color: $theme-red !important;
            .ant-menu-submenu-arrow::before, .ant-menu-submenu-arrow::after {
                color: $theme-red!important;
            }
        }
    }
    .menu-item-style{
        display: flex;
        align-items: center;
        background: $white;
        color: #33485D;
        font-family: $theme-font-family;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;
        border-radius: 10px;
        padding: 20px 18px;
        &:hover{
            color: $theme-red;
            .icon-menu{
                filter: invert(44%) sepia(97%) saturate(4287%) hue-rotate(334deg) brightness(90%) contrast(105%);
            }
        }
    }
    .menu-item-style::after{
        border-right-width: 0px !important;
    }
    .icon-menu{
        height: 23px;
        width: 24px;
    }
}
.logo-container{
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 15px 0px;

    .logo {
        width: 200px;
    }
    .side-logo-txt{
        font-size: 22px;
        font-weight: bold;
        color: #ec2a2a;
        font-style: italic;
        text-align: end;
    }
}

//ipad
@media screen and (max-width: 768px){
    .close-icon{
        display: unset;
    }
    .sidebar-div{
        position: fixed;
        z-index: 10;
        height: 100%;
    }
    .logo-container{
        justify-content: space-around;
    }
}

