@import ".././../../node_modules/bootstrap/scss/functions";
@import ".././../../node_modules/bootstrap/scss/variables";
@import ".././../../node_modules/bootstrap/scss/mixins";

//font
$theme-font-family: DM Sans;

//theme-colour
$primary: #ec2227;
$highlight1: #b2aba5;
$highlight2: #6f6763;
$highlight3: #8c326a;
$highlight4: #f1ebe9;
$danger: #ee3524;
$secondary: #ffa500;
$brandcolor1: #002a54;
$brandcolor2: #f86a00;
$brandcolor3: #d90d4a;
$brandcolor4: #EC2227E5;
$table-body: #534E4E;
$grey: #ebebeb;
$light: #e5e5e5;
$dark: #000000;
$white: white;
$grey1: #848484;
$grey2: #a6a6a6;
$grey3: #c4c4c4;
$success: #32a071;


$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "danger": $danger,
  "success": $success,
  "light": $light,
  "dark": $dark,
);

$custom-theme-colors: (
  "highlight1": $highlight1,
  "highlight2": $highlight2,
  "highlight3": $highlight3,
  "highlight4": $highlight4,
  "brandcolor1": $brandcolor1,
  "brandcolor2": $brandcolor2,
  "brandcolor3": $brandcolor3,
  "brandcolor4": $brandcolor4,
  "table-body": $table-body,
  "grey": $grey,
  "grey1": $grey1,
  "grey2": $grey2,
  "grey3": $grey3,
  "white": $white,
);

$theme-colors: map-merge($theme-colors, $custom-theme-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
$utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
$utilities-text-colors: map-loop($utilities-colors, rgba-css-var, "$key", "text");
$utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, "$key", "bg");

@import "bootstrap";
