@import "../../scss/config.scss";

.search-div{
    display: flex;
    align-items: center;
    // padding: 17px;
    width: auto;
    max-width: 0px;
}
.table-footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    .row-entries{
        display: flex;
        align-items: center;
        font-family: $theme-font-family;
        font-size: 15px;
        line-height: 18px;
    }
}
.active-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background: #4AD983;
    border-radius: 10px;
    color: white;
}

.custom-select-icon{
    .ant-select-arrow{
        top: 39%;
    }
}

.sb{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.sa{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

//ipad
@media screen and (max-width: 768px){
    .table-footer{
        height: 150px;
        flex-direction: column;
        .row-entries{
            height: 100px;
            flex-direction: column;
            align-items: baseline;
            justify-content: space-evenly;
        }
    }
}