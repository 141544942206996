
.main-content {
    padding: 20px 24px;
}

.sb {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sa {
    display: flex;
    justify-content: space-around;
    align-items: center;
}
