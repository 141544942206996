.main-content{
    padding: 20px 24px;
    overflow: auto;
}

.piolt-per-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    &-legend {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .lengen-col {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
}

.info {
    h3 {
        color: #ea353d;
        font-size: 40px;
        text-align: center;
    }

    span {
        display: block;
        text-align: center;
    }
}

.info-border {
    border:2px solid #dbdbdb;
    width:110px;
    margin:0px 5px;
    padding:5px 5px;
    min-height:130px;
    float:left;

    h3 {
        color: #ea353d;
        font-size: 30px;
        text-align: center;
    }

    span {
        display: block;
        text-align: center;
        text-transform:uppercase;
    }
}

.ant-card-head-title{padding:5px 0px !important;}
.ant-card-head{min-height:40px !important;}