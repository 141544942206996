@import '../../scss/config.scss';

.my-modal{
    .ant-modal-content{
        background: transparent;
        box-shadow: unset;
    }
    .ant-modal-body{
        background: white;
        border-radius: 25px;
    }
}