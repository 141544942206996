@import "../../scss/config.scss";
.home-page {
    background-image: url("../../../assets/images/Home.png");
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;

}
.divide {
    width: 250px;
    height: 4px;
}
.login-container-new {
    background: rgba(0, 0, 0, 0.6);
}
.bg-50 {
    background-color: rgba(34, 34, 34, 0.3);
    min-height: 100vh !important;
}
.h4-web
{
    width: 80%;
}

.forgot-txt{
    cursor: pointer;
    font-family: $theme-font-family;
    font-size: 16px !important;
    line-height: 26px;
    text-align: end;
    &:hover{
        text-decoration: underline;
    }
}

.password-eye img{
    width: 24px;
    height: 20px;
}