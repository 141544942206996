.remove-textarea-focus {
  position: relative;
  textarea {
    outline-style: none;
    box-shadow: none;
    border-color: transparent;
  }
  .default-textarea {
    background-color: #0000000d;
  }

  .textarea-Shadowbox{
    background: #FFFFFF;
    box-shadow: 0px -4px 24px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border:none
  }

  
  textarea[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    cursor: pointer;
    position: absolute;
    right: 0;
    width: 100%;
  }
}

.textarea-back {
  background-color: #0000000d;
}

.textarea-height {
  min-height : 80px
}
