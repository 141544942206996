.sp-radio {
.radioLabel {
  display: block;
  padding: 3px;
  position: relative;
  padding-left: 20px;
  margin-top: 10px;
  margin-left: 10px;
}

.read-input{
  user-select: unset;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  caret-color: transparent;
  &-select {
      background-size: 9.5px !important;
      background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right;
      margin-right: 10px;
      background-position-x: 99.2% !important;
  }
}

.radioLabel input {
  display: none;
}

.radioLabel span {
  border: 2px solid #ccc;
  position: absolute;
  overflow: hidden;
  line-height: 1;
  text-align: center;
  border-radius: 100%;
  font-size: 9pt;
  left: 5px;
  top: 50%;
  margin-top: -13.5px;
  padding: 3px 6px;
  
}

.radioLabel span:hover {
  cursor: pointer;
  background: #ec2227;
  opacity: 0.5;
}

input:checked + span {
  background: #ec2227;
  opacity: 0.5;
  color: white
}
}