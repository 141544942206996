@import '../../scss/config.scss';

.noti-style{
    .ant-notification-notice-description{
        font-size:17px !important;
    }
    .ant-notification-notice-close{
        color: $black !important;
        display: flex;
        justify-content: flex-end;
        position: unset !important;
    }
    .ant-notification-close-icon{
        font-size: 16px;
        &:hover{
            font-size: 20px;
        }
    }
}