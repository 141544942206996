@import '../../scss/config.scss';

.card-style{
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    width: auto;
    .ant-card-head{
        background: #33485D;
        color: white;
        border-radius: 10px 10px 0px 0px;
    }
}