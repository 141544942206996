.main-div {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.container-xxxl {
  width: 100%;
  min-height: 100vh;
}

@media screen and (min-width: 1400px) {
  .container-xxxl {
    margin: auto;
    // width: 1440px;
  }
}
