.remove-search-focus {
  input {
    height: 36px;
    outline-style: none;
    box-shadow: none;
    border-color: transparent;
    background-image: url("../../../../assets/svg/NewIcons/search_icon.svg");
    background-position: 10px center;
    background-repeat: no-repeat;
    padding-left: 30px !important;
  }
}

.sp-input-search{
/* clears the 'X' from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
}


