.floating-header-container {
  height: 100%;
  width: 400px;
  position: fixed;
  z-index: 100;
  top: 0px;
  right: -400px;
  background: rgba(236, 34, 39, 0.9);
  user-select: none;

  .floating-bottom-textContainer {
    position: absolute;
    bottom: 20px;
    width: 70%;
  }

  .floating-header-action {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;

    img {
      width: 100%;
    }
  }
}

.mh-vh-80 {
  height: 60vh;
}

.lites-logo {
  // width:381px;
  color: white;
  font-size: 28px;
  font-weight: bold;
  font-style: italic;
}
