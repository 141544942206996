
.sp-badge{
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.sp-badge-xs {
height: 24px;
width: 24px;
}

.sp-badge-sm {
height: 28px;
width: 28px;
}

.sp-badge-md {
height: 32px;
width: 32px;
}

.sp-badge-lg {
height: 36px;
width: 36px;
}

.sp-badge-xl {
height: 42px;
width: 42px;
}
