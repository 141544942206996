@import "../../scss/config.scss";

.heading-info {
    //display: flex;
    //flex-direction: row;
    max-width: 300px;
    .heading-warning {
        position: absolute;
        right: 20px;
        color: #ec2227;
        text-decoration: underline;
    }
}


.custom-table{
    border-collapse: separate !important;
    border-spacing: 0px 30px;
}


