.remove-input-focus {
  position: relative;
  input {
    outline-style: none;
    box-shadow: none;
    border-color: transparent;
  }
  .default-input {
    background-color: #0000000d;
  }

  .input-Shadowbox{
    background: #FFFFFF;
    box-shadow: 0px -4px 24px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border:none
  }

  
  input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    cursor: pointer;
    position: absolute;
    right: 0;
    width: 100%;
  }
}

.input-back {
  background-color: #0000000d;
}

.input-height {
  height: 32px;
}
