@import "../../scss/config.scss";

.display-card {
    color: white;    
    min-width: 160px !important;
    width: 20% !important;
    padding: 0 15px;
    &-content {
        display: flex;
        flex-direction: column;
        text-align: center;
        border-radius: 5px;
        height: 95px;
        min-width: 100px;
        justify-content: center;

        &-value {
            font-size: 2em;
            font-weight: bold;
        }
        margin-bottom: 15px;
    }
}

.card-1 {    
    background: #b2aba5;
}
.card-2 {
    background: #f86a00;
}
.card-3 {
    background: #ffa500;
}
.card-4 {
    background: #d90d4a;
}

.card-5 {
    background: #EC2227E5;
}

.has-click {
    cursor: pointer;
}

.view-all {
    color: #ec2227;
    font-weight: bold;
    &:after {
        padding: 0 5px;
        content: ">";
    }
    &:hover{
        text-decoration: underline;
    }
}
