@import "../../scss/config.scss";

.header-main{
    width: 100%;

    .header-div{
        display: flex;
        height: 60px;
        background: #E5E5E5;
        width: 100%;
        justify-content: space-between;
        padding: 0px 25px;
        align-items: center;
    }
    .header-profile-logo{
        height: 38px;
        cursor: pointer;
        padding: 0;
        border: 0;
        outline: 0;
    }
}

.close-collapse{
    padding: 5px;
    height: fit-content;
    max-height: 60px;
    cursor: pointer;
}

.drop-container{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu {
    background: $white;
    border-radius: 10px;
    position: absolute;
    top: 43px;
    right: 10px;
    width: 280px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
}

.menu.active {
    z-index: 3;
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

.menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
  
.menu li {
    font-family: $theme-font-family;
    font-size: 17px !important;
    line-height: 26px;
    width: auto;
    text-align: center;
    padding: 10px;
    border-bottom: 1px solid #dddddd;
}

.profile-container{
    padding: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: $theme-font-family;
    font-size: 18px !important;
    line-height: 26px;

    .profile-img{
        height: 6ch;
    }
}

.click-item{
    margin: 0px 10px;
    cursor: pointer;
    &:hover{
        font-weight: bold;
        color: $theme-red;
    }
}

.profile-name{
    font-family: $theme-font-family;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    border: 3px solid #33485D;
    border-radius: 20px;
    height: 38px;
    display: flex;
    align-items: center;
    width: 38px;
    justify-content: center;
    cursor: pointer;
}
