@import "../../scss/config.scss";

.main-content {
    padding: 20px 24px;
}
.blue{
    font-weight: bold;
    color: rgb(0,123,212);
}
.login-wrapper {
    justify-content: center;
    display: flex;
    align-items: center;
    min-height: 100vh;
    width:100%;
    background:linear-gradient(#FA545C,#fc989d);
    background-color:$theme-red /*this your primary color*/
}
.logo-login{
    height: 115px;
}
.login-container{
    height: 38rem;
    background: $white;
    width: 23rem;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 25px;

    .input-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .login-inner-div{
        height: inherit;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;
    }

    .login-txt{
        font-family: $theme-font-family;
        font-size: 30px;
        line-height: 26px;
        font-weight: bold;
        color: #33485D;
    }

    .login-btn{
        width: 28ch;
        height: 50px;
        border-radius: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 19px;
        cursor: pointer;
        background:linear-gradient(#FB9EA2,#FA545C,#EA353D);
        background-color:$theme-red;
        &:hover{
            font-weight: bold;
            box-shadow: 3px 3px 6px rgba(250,0,0,0.5);
        }
    }

    .forgot-txt{
        cursor: pointer;
        font-family: $theme-font-family;
        font-size: 16px !important;
        line-height: 26px;
        text-align: end;
        &:hover{
            text-decoration: underline;
        }
    }

    .error-txt{
        margin: 0px 13px;
        font-size: 0.75rem;
        margin-top: 3px;
        text-align: left;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 400;
        line-height: 1.66;
        letter-spacing: 0.03333em;
        color: #f44336;
    }

    .error-style{
        font-size: 16px;
        text-align: center;
        font-family: $theme-font-family;
        font-weight: 400;
        color: #f44336;
    }
    
    .visiblity{
        cursor: pointer;
    }
}

//mobile
@media screen and (max-width: 480px){
    .login-container{
        width: 21rem;
    }
}

//medium mobile(moto-g4)
@media screen and (min-width: 330px) and (max-width: 360px){
    .login-container{
        width: 20rem;
    }
}

//mini mobile
@media screen and (max-width: 320px){
    
}