$black: black;
$white: white;
$primary: #1266F1;
$danger: #F93154;
$light-blue: #007bff;

 $theme-font-family: DM Sans;
$theme-red:#B72252;
$disable-colour: #c4c4c4;
//utils
.cp{
    cursor: pointer;
}

.box-shadow{
    box-shadow: 0px 4px 4px 0px #0000001A;

}
//font-sizes
.fs-10{
    font-size: 10px;
}
.fs-11{
    font-size: 11px;
}
.fs-12{
    font-size: 12px;
}
.fs-13{
    font-size: 13px;
}
.fs-14{
    font-size: 14px;
}
.fs-15{
    font-size: 15px;
}
.fs-16{
    font-size: 16px;
}
.fs-17{
    font-size: 17px;
}
.fs-18{
    font-size: 18px;
}
.fs-19{
    font-size: 19px;
}
.fs-20{
    font-size: 20px;
}
.fs-21{
    font-size: 21px;
}
.fs-22{
    font-size: 22px;
}
.fs-23{
    font-size: 23px;
}
.fs-24{
    font-size: 24px;
}
.fs-25{
    font-size: 25px;
}
.fs-26{
    font-size: 26px;
}
.ant-modal{
    width: 800px !important;
}