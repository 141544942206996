.table-bordered {
    > thead > tr > td:not(:first-child, :last-child) {
      border: none;
    }
  
    > thead > tr {
      > td:first-child {
        border-right: none;
        border-bottom: none;
      }
  
      > td:last-child {
        border-left: none;
        border-bottom: none;
      }
    }
  
    > tbody > tr > td:not(:last-child, :first-child) {
      border-color: #ebebeb !important;
      border-right-width: 2px !important;
      border-left-width: 2px !important;
    }
  
    > :not(caption) > * {
      border-width: 0 !important;
    }
  
    > :not(caption) > * > * {
      border-bottom-width: 1px !important;
    }
  }
  
  .rounded-25 {
    border-radius: 25%;
  }
  .rounded-50 {
    border-radius: 50%;
  }
  .loaderModal{
    background: transparent;
    box-shadow: none;
}

.picker-height{
  height: 35px;
  // background-color: #ebebeb;
}


.customize-scheduler {
   z-index : 1 ;

   .MuiToolbar-regular .MuiIconButton-label{
    .MuiSvgIcon-root {
      fill: white !important;
   }
   }
 
  .makeStyles-cell-65 {
      border-right: none !important;
  }
  .Cell-cell-46 {
      border-right: none !important;
  }
  
  .makeStyles-table-64 {
      min-width: 550px !important;
  }
  .makeStyles-table-37 {
      min-width: 550px !important;
  }
  .MuiPaper-elevation1{
      box-shadow: none !important;
  }
}

.radioLabel {
  display: block;
  padding: 3px;
  position: relative;
  padding-left: 20px;
  margin-top: 10px;
  margin-left: 10px;
}
.react-responsive-modal-container{

  overflow-x: auto !important;
  
  }

  .training-modal-close-button{
    fill: white;
    margin-right: 18px;
    margin-top : -10px;
}

.training-modal{
  max-width: none;
  background: transparent;
  box-shadow: none;
  min-width:70%
}

.header-color-modal{
  color: #848484;
  font-weight: 500;
 padding-left: 20px !important;
 padding-right: 10px !important;
 }

.content-color-modal{
   color:#848484;
   font-weight: normal;
   padding-left: 20px !important;
   padding-right: 10px !important;
   
}

.view-logo-div {
  display: flex;
  justify-content: center;
  align-items: center;
  .view-logo-img {
      width: 300px;
      &.logo-100 {
          height: 100px;
      }
  }
}
.svg-menu-link{

  filter: brightness(0) invert(1) !important;
}

